@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .tooltip {
    @apply relative bg-blue-100 text-blue-800 px-spacing-8 rounded-4sm py-spacing-2;
    display: inline-flex;
  }
  .tooltipContent {
    @apply transition-all flex justify-center opacity-0 peer-hover:opacity-100 duration-300 absolute 
    bg-neutral-field p-spacing-10 rounded-4sm top-full mt-spacing-10 z-0 peer-hover:z-10;
    width: 200px;
  }
  .tooltip-text {
    @apply text-base-gray700 text-base;
    font-family: 'Inter';
  }
  .arrow-up {
    @apply absolute -top-spacing-6 w-0 h-0 border-x-6 border-solid border-x-transparent border-b-6 border-b-neutral-field;
  }
}
