@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .input {
    @apply bg-transparent rounded-lg focus:outline-none focus:border focus:border-interactive-borderActive transition-all;
    position: relative;
    z-index: 1;
  }
  .input + label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .input.disabled {
    @apply text-neutral-textDisabled;
  }
  .input.disabled + label {
    @apply text-neutral-textDisabled;
  }
  /* VARIANTS */
  .input.default {
    @apply px-spacing-16 h-spacing-56;
  }
  .input.small {
    @apply p-spacing-12 h-spacing-40;
  }

  /* ERROR STATE */
  .input.error {
    @apply border-negative-borderActive border;
  }
}
